import React from 'react';


// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';




import './Publications.css';
import AVTR4 from '../../ASSETS/jaredd-craig-HH4WBGNyltc-unsplash.jpg';

const data = [

  {
    id: AVTR4,
    name:"Digital Twin’s Anatomy: A Cross-Sector Framework with Healthcare Validation",
    review: "Digital twins (DT) in manufacturing, healthcare, and across different industrial domains are often over-simplified as solely a virtual representation of a physical object or service. Such a definition constitutes a dilemma in distinguishing DTs from digital models, digital shadows, digital threads, and cyber-physical systems. In this article, we aim to elucidate the concept of digital twins and its definition. Therefore, we go through the connotation of digital twins, which has its roots in space exploration and product-life cycle management, and describe the four evolution stages of DT developments. This article employs an ontological approach to clearly and comprehensively define digital twins and related key concepts, including digital models, assets, prototypes, shadows, and threads. Additionally, it presents a structured framework detailing the meta-model and the reference-level ontology of digital twins. To evaluate the proposed structure, definitions, and its important entities, we have examined our framework against 73 peer-reviewed papers in the healthcare sector from 2018 until July 2024. The evaluation and classification criteria of the selected works were based on four research questions. These criteria are driven by the core definitions provided by the main cross-domain digital twin researchers and this article’s proposed anatomy of digital twins."
    
    
  },
  {
    id: AVTR4,
    name:"Stable Heuristic Miner 2: Evaluating the Statistical Stability in Event Logs to Discover Business Processes",
    review: "Automated process discovery as one of the paradigms of process mining has attracted both industries and academic researchers. These methods offer visibility and comprehension out of complex and unstructured event logs. Over the past decade, the classic heuristic miner and applied heuristic-based process discovery algorithms showed promising results in revealing the hidden process patterns in information systems. One of the challenges related to such algorithms is the arbitrary selection of recorded behaviors in an event log. The offered filtering thresholds are manually adjustable, which could lead to the extraction of a non-optimal process model. This is also visible in commercial process mining solutions. Recently, the first version of the stable heuristic miner algorithm targeted this issue by evaluating the statistical stability of an event log. However, the previous version was limited to evaluating only activities’ behaviors. In this article, we’ll be evaluating the statistical stability of both activities and edges of a graph, which could be discovered from an event log. As a contribution, the stable heuristic miner 2 is introduced. Consequently, the definition of the descriptive reference process model has improved. The novel algorithm is evaluated by using two real-world event logs. These event logs are the familiar Sepsis data set and the urology department patients’ pathways event log, which is recorded by monitoring the interpreted location data of patients on hospital premises and is shared with the scientific community in this article."
    
    
  },

  {
    id: AVTR4,
    name:"Events-based semantic services composition in Industry 4.0 using Asset Administration Shell meta-model for digital twins",
    review: "Since the emergence of the Semantic Web concept, considerable work has focused on service composition using ontology-based approaches. Meanwhile, the concept of Industry 4.0 has emerged, emphasizing the benefits of utilizing data and computing devices in close proximity to production lines, exemplified by concepts like digital twins. However, these two fields rarely intersect, and the requirements for integrating domain-specific knowledge into business processes with event feedback during processes execution differ between these contexts. With the recent advancements in the semantization of industrial standards, such as the Asset Administration Shell, this work explores the elements of a semantic model for describing equipment, enabling the semantic composition of equipment as services. We propose an ontology, COMPAAS, designed to facilitate the composition of production lines that can react to events reported by their components, allowing the system to adjust its behavior accordingly. This approach also addresses the removal and addition of hardware or software elements within the chain, and the entire concept is validated through a minimal use case that demonstrates the improved flexibility of the production line in response to potential disturbances."
    
    
  },
  {
    id: AVTR4,
    name:"DIAG Approach: Introducing the Cognitive Process Mining by an Ontology-Driven Approach to Diagnose and Explain Concept Drifts",
    review: "The remarkable growth of process mining applications in care pathway monitoring is undeniable. One of the sub-emerging case studies is the use of patients’ location data in process mining analyses. While the streamlining of published works is focused on introducing process discovery algorithms, there is a necessity to address challenges beyond that. Literature analysis indicates that explainability, reasoning, and characterizing the root causes of process drifts in healthcare processes constitute an important but overlooked challenge. In addition, incorporating domain-specific knowledge into process discovery could be a significant contribution to process mining literature. Therefore, we mitigate the issue by introducing cognitive process mining through the DIAG approach, which consists of a meta-model and an algorithm. This approach enables reasoning and diagnosing in process mining through an ontology-driven framework. With DIAG, we modeled the healthcare semantics in a process mining application and diagnosed the causes of drifts in patients’ pathways. We performed an experiment in a hospital living lab to examine the effectiveness of our approach."
    
    
  },
  {
    id: AVTR4,
    name:"Stable Heuristic Miner Algorithm : applying statistical stability to discover the common patient pathways from location event logs",
    review: "Purpose-The classic heuristic miner algorithm has received lots of attention in the healthcare sector for discovering patient pathways. The extraction of these pathways provides more transparency about patient activities. The previous versions of this algorithm receive an event log and discover several process models by using manually adjustable thresholds. Then, the expert is left with the difficult task of deciding which discovered model can serve as the descriptive reference process model. Such a decision is completely arbitrary and it has been seen as a major structural issue in the literature of process mining. This paper tackles this problem by proposing a new process discovery algorithm to facilitate patient pathways diagnosis. Approach-To address this scientific challenge, this paper proposes to consider the statistical stability phenomenon in an event log, and it introduces the stable heuristic miner algorithm as its contribution. To evaluate the applicability of the proposed algorithm, a case study has been presented to monitor patient pathways in a medical consultation platform. Originality-Thanks to this algorithm, the value of thresholds will be automatically calculated at the statistically stable limits. Hence, instead of several models, only one process model will be discovered. To the best of our knowledge, applying the statistical stability phenomenon in the context of process mining to discover a reference process model from location event logs has not been addressed before. Findings / Practical implications-The results enabled to remove the uncertainty to determine the threshold that represents the common patient pathways and consequently, leaving some room for potential diagnosis of the pathways."
        
  },
  {
    id: AVTR4,
    name:"Monitoring and analyzing patients’ pathways by the application of Process Mining, SPC, and I-RTLS",
    review: "The strive of healthcare organizations for learning how their processes are influencing the quality of their services and mostly the satisfaction level of their patients is encouraging this research work to propose a method which provides comprehensive knowledge in that regard for healthcare experts. This paper aims at suggesting an approach to model the pathways of patients as business processes and to measure the performance level of their executions based on the distance that patients take. In this order, Indoor-Real Time Localization Systems (I-RTLS) has been introduced as the tool to monitor patients’ pathways; Process Mining techniques will enrich the level of information by discovering the process models. Statistical Process Control (SPC) methods will help to measure the performance stability of processes. An outline of the approach is highlighted by a study case which had been designed at a hospital in France. The results show that the proposed approach could provide accurate data and comprehensive knowledge about patients’ pathways and the execution of the processes in hospitals."
  },
  {
    id: AVTR4,
    name:"Evaluating the Process Capability Ratio of Patients' Pathways by the Application of Process Mining, SPC and RTLS.",
    review: "Learning how patients receive their health treatments is a critical mission for hospitals. To fulfill this task, this paper defines patients’ pathways as business process models and tries to apply process mining, realtime location systems (RTLS), and statistical process control (SPC) as a set of techniques to monitor patients’ pathways. This approach has been evaluated by a case study in a hospital living lab. These techniques analyze patients’ pathways from two different perspectives:(1) control-flow and (2) performance perspectives. In order to do so, we gathered the location data from movements of patients and used a proof of concept framework known as R. IO-DIAG to discover the processes. To elevate the performance analyses, this paper introduces the process capability ratio of the patients’ pathways by measuring the walking distance. The results lead to the evaluation of the quality of business processes."
  },
  {
    id: AVTR4,
    name:"A Conceptual Framework to Support Discovering of Patients' Pathways as Operational Process Charts",
    review: "To offer high quality services to patients, hospitals try to get a perception of patients' processes. This research work aims at identifying a type of processes known as patients' pathways and to devise a framework which could support the task of discovering patients' pathways. Existing modeling languages for discovering patients' processes fail at extracting the value and nature of each activity relevant to the whole process. To fill the identified gap, this paper uses a new approach for visualizing patients' pathways as Operational Process Charts (OPC). To do so, Real-Time Location Systems (RTLS) have been used to extract the primary event logs which contain the data related to movements of patients. Next, we have designed a meta-model which can filter and analyze the RTLS event logs and identify the different elements for the construction of OPC's. This paper focuses on presenting the DIAG meta-model for interpretation of event logs and the transformation of these data into opc's."
  },
  {
    id: AVTR4,
    name:"Interpretation of Patients' Location Data to Support the Application of Process Mining Notations",
    review: "The application of indoor localization and process mining emerges as an intriguing tool for the researchers to address the structural issues related to the patient pathways inside healthcare organizations. However, there is a major gap in the literature. This is related to the lack of enough attention to the interpretation of location data. Therefore, as a contribution, this article presents the DIAG meta-model and relevant location data interpretation rules. This model-driven approach has been realized in the context of the R-IOSUITE application and it supports the further analyses by the process mining methods."
  },
  {
    id: AVTR4,
    name:"Applying Process Mining and RTLS for Modeling, and Analyzing Patients’ Pathways",
    review: "Purpose: This paper aims at introducing a generic approach for visualizing, analyzing and diagnosing patients’ pathways. This approach could be categorized as a business intelligence approach to extract knowledge for decision makers in healthcare organizations. The analyses provided by this approach are based on the location data which are being recorded in the information system (IS) by indoor-Real-Time Location Systems (RTLS). Findings: Healthcare organizations are getting more eager to learn from the execution of their processes. They seek different tools and approaches to analyze the processes and visualize the problems. This paper presents one of the possibilities to provide more understanding of process executions and it is based on the locations of the patients inside the organization. Approach: Business intelligence approaches provide new technical and technological solutions for business analysts to improve the quality of products and services within organizations. The approach of this work helps to visualize patients’ pathways and analyze them by associating real-time localization and process mining. This approach consists of four phases in which several functionalities have been defined. These phases are Data, Information, Awareness, and Governance (DIAG). Additionally, a case study has been designed to illustrate the DIAG approach."
  },
  {
    id: AVTR4,
    name:"A methodology for business process discovery and diagnosis based on indoor location data : Application to patient pathways improvement",
    review: "Business processes are everywhere and, as such, we must acknowledge them. Among all of them, hospital processes are of vital importance. Healthcare organizations invest huge amount of efforts into keeping these processes under control, as the allowed margin of error is so slight. This research work seeks to develop a methodology to endorse improvement of patient pathways inside healthcare organizations. It does so by using the indoor location data of patients. This methodology is called DIAG (Data state, Information state, Awareness, Governance). It is constructed of several different functions. The most important ones are as follows: (i) location data interpreting, (ii) automatic discovery of business process models, (iii) business process analyzing for evaluating the performance and quality of processes, and finally, (iv) automatic diagnosing of business processes. Along the former functions, the contribution of this thesis are: The DIAG methodology which, through four different states, extracts knowledge from location data; the DIAG meta-model which supports both the interpretation of location data (from raw data to usable information) and the alignment of the domain knowledge (which are used for the diagnosing methods); two process discovery algorithms which explore statistical stability in event logs, application of Statistical Process Control (SPC) for the “enhancement notation” of Process Mining; the ProDIST algorithm for measuring the distance between process models; two automatic process diagnosing methods to detect causes of structural deviations in individual cases and common processes. The state of the art in this dissertation endorses the necessity for proposing such solutions. A case study within this research work illustrates the applicability of the DIAG methodology and its mentioned functions and methods."
  },
  {
    id: AVTR4,
    name:"An automatized data extraction approach for process mining and business process analysis",
    review: "In this paper we explain an abstracted version of our approach to provide a rapid business process modelling, and diagnosis for operational processes within organizations. This approach is oriented toward designing a set of tools which has three main functions; Tracking, Modelling, and Assessing. As a result, first we would gather the events automatically (Tracking), thanks to an Indoor Positioning Systems. Second, by using Process Mining we would be able to get the business process models (Modelling). Third, by using case-based, or heuristics algorithms and Discrete Event Simulation we want to propose a tool for process diagnosis and improvements (Assessing). This research project targeted three main fields which are Healthcare, Supply Chain Management, and Sport. This project is subjected as a doctoral thesis in a collaboration between Industrial Engineering Center of Ecole des Mines d’Albi-Carmaux and Maple High Tech in France."
  },
  
]

function Publications() {
  return (
    <section id='publications'>

    <h5>Journal / Conference</h5>
    <h2>PUBLICATIONS</h2> 

    <Swiper className="container testimonials__container"

    modules={[Navigation, Scrollbar, Pagination,  A11y]}
    spaceBetween={40}
    slidesPerView={1}
    grabCursor
    pagination={{clickable: true }}
    navigation
    scrollbar={{ draggable: true }}
    >
      
      {
        data.map(({id, name, review}, index)=>{
          return(
            <SwiperSlide key={index} className='testimonial'>
              <div className="client__avatar">

                <img src={id} alt="" />
              </div>
              <h5>{name}</h5>
                <small className='client__review'>{review}</small>
            </SwiperSlide>
      
          )
        })
      }

    </Swiper> 
    </section>
  )
}

export default Publications;