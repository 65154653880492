import React, { useRef, useState } from 'react';
import './Contact.css';
import {MdOutlineEmail} from 'react-icons/md';
import {RiMessengerLine} from 'react-icons/ri';
import {BsWhatsapp} from 'react-icons/bs';
import emailjs from 'emailjs-com';

function Contact() {

  const form = useRef();
  // user message after sending a correspondance
  const [showMessage, setShowMessage] = useState(false);
  const handleMsgUser = () => {
    setShowMessage(true);
  }
  const handleClose = () => {
    setShowMessage(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hy8rmlh', 'template_ae3kf9c', form.current, 'a6SQxxWNxnvm7Qpln')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset()
      };


  return (
    <section id='contact'>
        <h5>Get In Touch</h5>
        <h2>Contact me</h2>
        <div className="container contact__container">
          <div className="contact__options">
          <a href="mailto:sina8namaki@gmail.com">
            <article className='contact__option'>
              <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>  
            <a href="mailto:sina8namaki@gmail.com">Send a Message</a>
            </article>
            </a>
            {/* <article className='contact__option'>
              <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>  
            <h5>Sina NAMAKI</h5>
            <a href="https://www.facebook.com/sina.namaki/" target="_blank">Send a message</a>
            </article> */}
            {/* <a href="https://api.whatsapp.com/send?phone=+33768451401" target="_blank">
            <article className='contact__option'>
              <BsWhatsapp className='contact__option-icon'/>
            <h4>WhatsApp</h4>  
            <a href="https://api.whatsapp.com/send?phone=+33768451401" target="_blank">Send a message</a>
            </article>
            </a> */}
          </div>
          {/* END of CONTACT OPTIONS */}
          <form ref={form} onSubmit={sendEmail}>

            <input type="text" name='name' placeholder='Your Full Name' required/>
            <input type="email" name='email' placeholder='Your email' required/>
            <textarea name="message" placeholder='Your message...' required rows="7"></textarea>
            <button onClick={handleMsgUser} type='submit' className='btn btn-primary'>Send Message</button>
            {showMessage && <div className="overlay"> 
              <div className="popup"/>
              <p className='textPopup'>Hello! Thank you for your message, I will get back to you ASAP! </p> 
              <button onClick={handleClose} className="btn close-btn">Close</button>
              </div>}
          </form>
        </div>
    </section>
  )
}

export default Contact