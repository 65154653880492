import React from 'react';
import './About.css';
// import ME from '../../ASSETS/photo_2022-03-13_16-33-06.jpg';
import ME from '../../ASSETS/20211017_140229529_iOS.jpg';
// import ME from '../../ASSETS/20211017_130354200_iOS.jpg';
import {FaAward} from 'react-icons/fa';
import {GiFactory} from 'react-icons/gi';
import {VscFolderLibrary} from 'react-icons/vsc';
import {FaUsers} from 'react-icons/fa';


function About() {

 

  return (
    <section id='about' className='about__main'>
      <h5>Get to Know</h5>
      <h2>About Sina</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="" />  
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article   className='about__card'>
              <FaUsers className='about__icon'/>
              <h5>My Team</h5>
              <small>Ph.D. researchers & Master students</small>
            </article>
            <article className='about__card'>
              <GiFactory className='about__icon'/>
              <h5>3+ Industries</h5>
              <h6>Software Engineering</h6>
              <h6>Automobile</h6>
              <h6>Supply Chain</h6>
              <h6>Healthcare</h6>
            </article>
            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>10+ projects</small>
            </article>
          </div>

          <p>
          In everything I do, I believe in de-stressing, simplifying and improving our lives. To do so, I work on solving problems in different industries by optimizing business processes. I am not perfect in it, but I chase excellence in how and what I do.
          </p>
          <div className="portofolio__item-cta">
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
          <a href="https://www.aparat.com/v/x32KZ" target='_blank' className='btn '>One of my Lectures</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About;