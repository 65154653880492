import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

import Header from './COMPONENTS/Header/Header';
import Nav from './COMPONENTS/Nav/Nav';
import About from './COMPONENTS/About/About';
import Experience from './COMPONENTS/Experience/Experience';
import Projects from './COMPONENTS/Projects/Projects';
import Portofolio from './COMPONENTS/Portofolio/Portofolio';
import Publications from './COMPONENTS/Publications/Publications';
import Contact from './COMPONENTS/Contact/Contact';
import Footer from './COMPONENTS/Footer/Footer';



const App = () => {
  // let routes;
  // routes=(
  //   <Switch>
  //     <Route exact path='/researchTeam'component={Publications}></Route>
  //   </Switch>
  // )

  return (
    <>
      <Header />
      <Nav/>
      <About/>
      <Experience/>
      <Projects/>
      <Portofolio/>
      <Publications/>
      <Contact/>
      <Footer/>
    </>
  )
}

export default App