import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import CV from '../../ASSETS//Sina NAMAKI ARAGHI -short CV.pdf';

 
const CTA = () => {
  // ---> Navigate to the team page
 const history = useHistory();

 const routeChangeTeam = ()=> {
   let path = '/researchTeam';
   history.push(path);
   console.log('sinnn')
 }
  return (
    <div className='cta'>
        <a href={CV} download className='btn'> Download CV </a>    
        <a onClick={routeChangeTeam}   className='btn btn-primary'>My Team</a>
        {/* <a href="#contact" className='btn'>My Team</a> */}
    </div>
  )
}

export default CTA;